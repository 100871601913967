// light
.bg-light {
	color: $text-dark;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		color: $text-dark;
	}

	h6,
	.h6 {
		&::after {
			background-color: $white !important;
		}
	}

	// a
	a:not(.btn):not(.card):not(.card-title-link):not(.card-btn) {
		color: $text-dark;
		text-decoration: underline;

		&:hover {
			color: $green-dark;
		}
	}

	// custom-list
	.custom-list,
	.custom-list-extra {
		li {
			&::before {
				color: $text-dark;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		&.slider {
			.owl-nav {

				.owl-prev,
				.owl-next {
					color: $text-dark;

					&:hover {
						color: lighten($text-dark, 15%);
					}
				}
			}

			.owl-dots {
				.owl-dot {
					span {
						border-color: $text-dark;
					}

					&:hover,
					&.active {
						span {
							background: $text-dark;
						}
					}
				}
			}
		}
	}
}

// dark
.bg-dark {
	color: $text-light;

	h6,
	.h6 {
		&::after {
			background-color: $white !important;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		color: $text-light;
	}

	// a
	a:not(.btn):not(.card):not(.card-title-link):not(.card-btn) {
		color: $text-light;
		text-decoration: underline;

		&:hover {
			color: $green;
		}
	}

	// custom-list
	.custom-list,
	.custom-list-extra {
		li {
			&::before {
				color: $text-light;
			}
		}
	}

	// alert
	.alert-danger {
		background: transparent;
	}

	// owl-carousel
	.owl-carousel {
		&.slider {
			.owl-nav {

				.owl-prev,
				.owl-next {
					color: $text-light;

					&:hover {
						color: darken($text-light, 15%);
					}
				}
			}

			.owl-dots {
				.owl-dot {
					span {
						border-color: $text-light;
					}

					&:hover,
					&.active {
						span {
							background: $text-light;
						}
					}
				}
			}
		}
	}
}

.bg-light,
.bg-dark {
	.page-block.faq-category {
		.faq-overview {
			.faq-category {
				.faq-items {
					.faq-item {
						.faq-question {
							.faq-category {
								h3 {
									color: $white;
								}
							}

							.faq-icon {
								color: $white;
							}

							&:hover {
								.faq-category {
									h3 {
										color: $green;
									}
								}
								.faq-icon {
									color: $green;
								}
							}
						}
					}
				}
			}
		}
	}
}
