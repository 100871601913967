// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 8vh;
		padding-bottom: 8vh;
	}
}

// eyecatcher-section
// =========================================================================
// .eyecatcher-section,
// .eyecatcher-section .owl-carousel .item {
// 	@include media-breakpoint-down(sm) {
// 		height: 790px;
// 	}
// }

.eyecatcher-section {
	position: relative;

	// @include gradient;
	// @include gradient-animation;
	background-color: $green-gray;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		@include media-breakpoint-down(sm) {
			display: none !important;
		}

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		&::after {
			content: "";
			position: absolute;
			inset: 0;
			background-image: url("/images/3wCurve.svg");
			background-size: auto 540px;
			background-position: top 0% right 33%;
			background-repeat: no-repeat;

			@include media-breakpoint-up(sm) {
				background-size: auto 120%;
				// background-position: top 0% right -300px;
			}

			@include media-breakpoint-up(lg) {
				// background-position: top 0% right -230px;
			}

			@include media-breakpoint-up(xl) {
				background-position: top right;
			}
		}

		&::before {
			// loader
			display: none !important;
		}

		.owl-item {
			.item {
				direction: rtl;
			}
			img.owl-lazy {
				width: auto;
				height: 440px;
				max-width: none;
			}

			@include media-breakpoint-up(sm) {
				img.owl-lazy {
					height: 600px;
				}
			}

			@include media-breakpoint-up(lg) {
				.item {
					direction: ltr;
				}
				img.owl-lazy {
					height: auto;
					max-width: 100%;
					width: 100%;
				}
			}

			// first item
			&:first-child {
				&.active {
					.item {
						img {
							@extend .slide-in-bottom;
						}
					}
				}
			}
			// every 5th item
			&:nth-child(4n + 1) {
				&.active {
					.item {
						img {
							@extend .slide-in-bottom;
						}
					}
				}
			}

			// every 2 items
			&:nth-child(even) {
				&.animated {
					.item {
						img {
							@extend .kenburns-top-right;
						}
					}
				}
			}

			// 3rd item & every 7th
			&:nth-child(4n + 3) {
				&.active {
					.item {
						img {
							@extend .slide-in-right;
						}
					}
				}
			}
		}

		.item {
			position: relative;
			align-items: flex-start;
			min-height: 300px;
			height: 760px;
			overflow: hidden;

			@include media-breakpoint-up(md) {
				height: 600px;
			}

			@include media-breakpoint-up(lg) {
				height: 700px;
			}

			@include media-breakpoint-up(xl) {
				height: 100vh;
			}

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			// picture {
			// 	display: none;
			// }

			.owl-caption-holder {
				position: relative;
				z-index: 30; // above picture
				align-self: center;

				.owl-container {
					max-width: 1140px;

					.owl-caption {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						padding: 8vh 0;
						text-shadow: none;
						text-align: left;

						.owl-title {
							max-width: 1110px;
							@include font-size($h1-font-size);

							@extend .line-before;

							&::before {
								content: "";
								max-width: 50%;
								animation-delay: 1s;
							}

							@include media-breakpoint-up(xl) {
								font-size: clamp(
									2.5rem,
									7.5vw,
									7.5rem
								); // 120px
							}

							animation: delay 1s 1s both;
						}

						.owl-subtitle {
							max-width: 600px;
							@include font-size($h4-font-size);
							margin-bottom: 0;
							color: $gray;
							animation: delay 2s 2s both;
						}

						.owl-btn {
							margin-top: 6vh;
							border-color: $green-gray;
							background-color: $green-gray;
							text-decoration: none;
							animation: delay 3s 3s both;

							@include media-breakpoint-up(xl) {
								padding-left: 35px;
								padding-right: 35px;
							}

							&:hover {
								border-color: $green-dark;
								background-color: $green-dark;
							}
						}
					}
				}
			}
		}

		// .owl-item {
		// 	&.active {
		// 		.item {
		// 			&.animation-people {
		// 				img {
		// 					animation: kenburns-top-left 10s ease both;
		// 				}
		// 			}

		// 			&.animation-work {
		// 				img {
		// 					animation: kenburns-bottom-right 10s ease both;
		// 				}
		// 			}

		// 			.owl-caption {
		// 				animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
		// 			}
		// 		}
		// 	}
		// }

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

	// banner content
	.banner-content {
		z-index: 9;
		position: absolute;
		inset: 0;
		display: flex;
		align-items: center;

		@include media-breakpoint-down(sm) {
			top: auto;
			padding-top: 2rem;
			padding-bottom: 2rem;
			background-color: $green;
			align-items: flex-end;
		}

		@include media-breakpoint-between(md, lg) {
			padding-left: 85px !important;
			padding-right: 85px !important;
		}

		.owl-title,
		.owl-subtitle,
		.owl-btn {
			animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
		}

		.owl-title,
		.owl-subtitle {
			line-height: 1;
			font-family: "Outfit", sans-serif;
			font-weight: 400;
		}

		.owl-title {
			// max-width: 1110px;
			margin-bottom: 1.5rem;
			color: $white;
			font-size: 40px;

			@include media-breakpoint-up(sm) {
				@include font-size($h1-font-size);
			}

			@extend .line-before;

			&::before {
				content: "";
				max-width: 50%;
				animation-delay: 1s;
			}

			@include media-breakpoint-up(xl) {
				font-size: clamp(2.5rem, 7.5vw, 7.5rem); // 120px
			}

			animation: delay 1s 1s both;
		}

		.owl-subtitle {
			max-width: 600px;
			margin-bottom: 0;
			color: $gray;
			animation: delay 2s 2s both;
			font-size: 1.5rem;

			@include media-breakpoint-up(sm) {
				@include font-size($h4-font-size);
			}

		}

		.owl-btn {
			.owl-btn {
				margin-top: 6vh;
				border-color: $green-gray;
				background-color: $green-gray;
				text-decoration: none;
				animation: delay 3s 3s both;
				color: $white !important;

				@extend .btn;
				@extend .arrow-after-white;

				@include media-breakpoint-up(xl) {
					padding-left: 35px !important;
					padding-right: 35px !important;
				}

				&:hover {
					border-color: $green-dark;
					background-color: $green-dark;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding-top: 75px !important;
	padding-bottom: 8vh !important;
	@extend .section-padding-x;

	@include media-breakpoint-up(xl) {
		padding-top: 160px !important;
		padding-bottom: 100px !important;
		font-size: 30px;
	}

	h1 {
		@extend .line-before;
	}

	h1.large {
		@include media-breakpoint-up(xl) {
			font-size: clamp(2.5rem, 7.5vw, 7.5rem); // 120px
		}
	}

	.info-nav {
		display: none;
	}

	.container-one-column {
		.container-holder {
			max-width: 1000px;

			.column {
				&.narrow {
					@include media-breakpoint-up(md) {
						max-width: 830px;
					}
				}
			}
		}
	}

	.container-two-columns {
		.container-holder {
			.column {
				&.wide {
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
				}

				&.narrow {
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}

				&.sidebar {
					@extend .line-before;

					@include media-breakpoint-up(xl) {
						font-size: 20px;
					}

					.wysiwyg {
						margin-top: 15px;
					}
				}
			}
		}
	}

	// blog-category-filter
	.blog-category-filter {
		margin-bottom: 0;

		.list {
			.list-item {
				margin-right: 0;
				font-size: 20px;

				+ li {
					&::before {
						content: "/";
						margin: 0 5px;
					}
				}

				.list-link {
					&:hover {
						color: $green-dark;
					}
				}
			}
		}
	}
}

&.contact-page {
	.lead-section {
		background-color: $green-dark !important;

		.faq-overview {
			max-width: 560px;
		}
	}
}

&.service-detail {
	.lead-section {
		.logo {
			margin: 30px 0 auto auto;
		}
	}
}

&.service-detail,
&.employee-overview,
&.blog-post-overview,
&.blog-post-detail,
&.vacancy-vacancy-overview,
&.vacancy-vacancy-detail {
	.lead-section {
		padding-bottom: 60px !important;
	}
}

// lead-banner-section
// =========================================================================
.lead-banner-section {
	position: relative;
	@extend .section-padding-x;

	.container-holder {
		align-items: flex-start;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 40%;
		background-color: $green;
	}

	// wysiwyg
	.wysiwyg {
		&:first-of-type {
			@include media-breakpoint-up(md) {
				color: $white;
			}

			@include media-breakpoint-up(xl) {
				font-size: 30px;
			}
		}

		&:last-of-type {
			@include media-breakpoint-up(md) {
				margin-top: 40%;
			}

			@include media-breakpoint-up(xl) {
				margin-left: 80px;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		min-height: auto !important;

		.item {
			min-height: auto !important;
			padding: 0 !important;
		}
	}
}

&.vacancy-vacancy-overview {
	.lead-banner-section {
		&::before {
			background-color: $green-gray;
		}
	}
}

// intro-section
// =========================================================================
.intro-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	.container-one-column {
		.container-holder {
			max-width: 1000px;

			.column {
				&.narrow {
					@include media-breakpoint-up(md) {
						max-width: 820px;
					}
				}
			}
		}
	}

	.wysiwyg {
		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}
	}
}

// clients-section
// =========================================================================
.clients-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		margin-top: 5rem;

		@include media-breakpoint-up(xl) {
			padding-left: 30%;
		}

		&.grid {
			.grid-items {
				.item {
					@include make-col(4);

					@include media-breakpoint-up(sm) {
						@include make-col(4);
					}

					@include media-breakpoint-up(md) {
						@include make-col(3);
					}

					@include media-breakpoint-up(lg) {
						flex: 0 0 20%;
						max-width: 20%;
					}
				}
			}
		}

		.card {
			border: none;
			border-radius: 0;
			filter: grayscale(1);

			.card-body {
				display: none !important;
			}

			&.flipping {
				animation: flipInX 1s;
			}

			&:hover,
			&.flipped {
				filter: grayscale(0);
			}
		}
	}
}

// latest-work-section
// =========================================================================
.latest-work-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	// link
	div.link {
		display: flex;

		@include media-breakpoint-up(md) {
			margin: auto 0 auto auto;
		}

		a.link {
			@extend .btn;
			@extend .btn-primary;
		}
	}

	// collection
	.collection {
		.item {
			height: auto !important;
			margin-top: 40px;
		}

		.card {
			@extend .default-card;

			.card-subtitle {
				display: none;
			}
		}
	}

	// highlight
	.highlight {
		.card {
			@extend .default-card;
			margin-top: 6rem;

			.card-subtitle {
				display: none;
			}
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	padding-top: 0 !important;
	padding-bottom: 0 !important;

	.container-two-columns {
		max-width: 2560px !important;
		padding-left: 0 !important;
		padding-right: 0 !important;

		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}

		.container-holder {
			align-items: center;
			margin: 0 !important;

			> .column {
				margin-bottom: 0;

				&.one {
					align-self: center;
					padding-top: 45px;
					padding-bottom: 45px;

					@include media-breakpoint-up(md) {
						padding-left: 85px !important;
					}

					@media (min-width: 1400px) {
						padding-left: 110px !important;
					}

					.wysiwyg {
						max-width: 750px;
						margin-left: auto;
					}
				}

				&.two {
					align-self: stretch;
					padding-right: 0 !important;

					@include media-breakpoint-down(sm) {
						padding-left: 0 !important;
					}

					img {
						min-height: 400px;
						height: 75vh;
						max-height: 880px;
						object-fit: cover;
					}
				}
			}
		}
	}

	.wysiwyg {
		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 8vh 0;

	@extend .section-padding-x;

	.container-one-column {
		.container-holder {
			.column {
				&.narrow {
					@include media-breakpoint-up(md) {
						max-width: 820px;
					}
				}
			}
		}
	}

	// faq-row
	.faq-row {
		margin-top: 8vh;

		@include media-breakpoint-up(xl) {
			margin-top: 80px;
		}

		&:nth-child(even) {
			@include media-breakpoint-down(sm) {
				.container-holder {
					flex-direction: column-reverse;
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	// owl-carousel
	.owl-carousel {
		.item {
			max-height: 880px;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.15);
			}

			picture {
				overflow: hidden;

				img {
					width: 100vw;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				@include media-breakpoint-up(md) {
					padding-left: 70px;
					padding-right: 70px;
				}

				.owl-container {
					position: relative;
					height: 100%;

					@media (min-width: 1400px) {
						max-width: 1660px;
						padding-left: 40px;
						padding-right: 40px;
					}

					.owl-caption {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						max-width: 1000px;
						height: 100%;
						padding: 8vh 0;
						text-shadow: none;
						text-align: left;

						.owl-title {
							margin-bottom: 0;
							@include font-size($h1-font-size);
							@extend .line-before;
						}

						.owl-subtitle {
							margin-bottom: 0;
						}

						.owl-btn {
							margin-top: auto;
						}
					}
				}
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				@include media-breakpoint-up(xl) {
					margin: 0 -40px -40px -40px;
				}

				.item {
					@include media-breakpoint-up(xl) {
						margin-bottom: 40px;
						padding-left: 40px;
						padding-right: 40px;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card;
					}
				}
			}
		}
	}
}

&.service-overview {
	.bundle-overview-section {
		position: relative;
		margin-top: 0;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 200px;
			background-color: $green;
		}

		// collection
		.collection {
			.card {
				.card-subtitle {
					display: none;
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 8vh 0;
}

// latest-vacancy-section
// =========================================================================
.latest-vacancy-section {
	@extend .section-padding-x;

	// collection
	.collection {
		margin-top: 6vh;

		.card {
			@extend .default-card;
			@extend .bg-dark;

			.card-image {
				display: none !important;
			}

			.card-body {
				padding-top: 0;

				.card-caption {
					flex: none;
				}
			}
		}
	}
}

// employee-bundle-overview-section
// =========================================================================
.employee-bundle-overview-section {
	margin: 8vh 0;

	@include media-breakpoint-up(xl) {
		margin: 80px 0;
	}

	@extend .section-padding-x;

	.container-two-columns {
		margin-bottom: 8vh;

		@include media-breakpoint-up(xl) {
			margin-bottom: 80px;
		}

		.container-holder {
			align-items: center;
		}

		@include media-breakpoint-up(xl) {
			&:nth-child(even) {
				.container-holder {
					.column {
						&.one {
							order: 1;
						}

						&.two {
							order: 0;
							align-items: flex-end;
						}
					}
				}
			}
		}

		.wysiwyg {
			max-width: 560px;

			@include media-breakpoint-up(xl) {
				font-size: 30px;
			}
		}

		.employee-group {
			max-width: 560px;
			width: 100%;

			.employee-item {
				position: relative;
				display: flex;
				flex-direction: column;

				@include media-breakpoint-up(lg) {
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
				}

				border-bottom: 1px solid $green-gray;
				padding: 10px 0;

				.name {
					@include media-breakpoint-up(lg) {
						width: calc(30% - 24px);
					}

					color: $green-dark;

					@include media-breakpoint-up(xl) {
						font-size: 24px;
					}

					font-weight: 400;
				}

				.service {
					@include media-breakpoint-up(lg) {
						width: calc(70% - 24px);
					}
				}

				.icon {
					position: absolute;
					top: 10px;
					right: 0;
					width: 24px;
					font-size: 24px;

					a {
						display: block;
					}
				}

				&:last-of-type:not(:first-of-type) {
					border-bottom: none;
				}
			}
		}
	}
}

// vacancy-bundle-overview-section
// =========================================================================
.vacancy-bundle-overview-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		margin: 45px 0;

		&.grid {
			.grid-items {
				.item {
					@include make-col(12);
					margin-bottom: 45px;

					.card {
						@extend .default-card;

						.card-image {
							display: none;
						}

						.card-body {
							padding-top: 0;

							.card-caption {
								.card-title {
									@include font-size($h4-font-size);
									font-weight: 400;
								}
							}
						}
					}
				}
			}
		}
	}
}

// blog-bundle-overview-section
// =========================================================================
.blog-bundle-overview-section {
	margin-bottom: 8vh;
	@extend .section-padding-x;

	// .blog-filter
	.blog-filter,
	.blog-category-filter {
		display: none;
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				@include media-breakpoint-up(xl) {
					margin: 0 -40px -40px -40px;
				}

				.item {
					@include media-breakpoint-up(xl) {
						margin-bottom: 40px;
						padding-left: 40px;
						padding-right: 40px;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card;

						.card-body {
							.card-caption {
								.card-subtitle {
									font-size: 14px;
									font-weight: 400;
								}
							}
						}
					}

					&:not(:nth-child(-n + 2)) {
						.card {
							.card-image {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// blog-detail-intro-section
// =========================================================================
.blog-detail-intro-section {
	@extend .section-padding-x;

	// image-collection
	.image-collection {
		.gallery-horizontal {
			.gallery-item {
				@include make-col(12);
			}

			.gallery-thumbs {
				display: none;
			}
		}
	}

	.info-nav {
		display: none;
	}

	.info {
		display: flex;
		flex-direction: column;

		.info-holder {
			display: flex;
			flex-flow: row wrap;
			margin-bottom: 30px;
		}

		.subtitle {
			order: 0;

			h5 {
				margin-bottom: 0;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.tags {
			display: flex;
			flex-flow: row wrap;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 0;
				font-size: 14px;

				&::before {
					content: "/";
					margin: 0 5px;
				}

				a {
					&:hover {
						color: $green-dark;
					}
				}
			}
		}

		.wysiwyg {
			order: 1;
			position: relative;
			margin-bottom: 30px;

			@include media-breakpoint-up(xl) {
				font-size: 30px;
			}

			@extend .line-after;

			&::after {
				margin-top: 30px;
				background-color: $green-gray !important;
			}
		}

		.title {
			order: 2;
		}
	}
}

// vacancy-vacancy-detail
// =========================================================================
&.vacancy-vacancy-detail {

	// bundle-detail-section
	.bundle-detail-section {
		.container-two-columns {
			.container-holder {
				.column {
					@include make-col(12);
				}
			}
		}
	}

	// button-cv
	div.button-cv {
		position: fixed;
		top: 15px;
		right: 15px;

		a {
			@extend .btn;
			@extend .btn-primary;
		}
	}
}

// services-detail
// =========================================================================
&.service-detail {
	.gallery-section {
		@extend .bg-dark;
		@extend .section-padding-x;
		overflow: hidden;

		// heading-holder
		.heading-holder {
			.column {
				flex-flow: row wrap;
			}

			h2 {
				@include media-breakpoint-up(xl) {
					font-size: 60px;
				}
			}

			.domain-link {
				display: flex;

				@include media-breakpoint-up(sm) {
					margin: auto 0 auto auto;
				}

				a {
					@extend .arrow-after-white;
					display: inline-flex;
					align-items: center;
					color: $white;
					font-weight: 700;
					text-decoration: none !important;
				}
			}
		}

		// gallery-holder
		.gallery-holder {
			width: 100vw;
			max-width: none;

			.container-holder {
				.column {
					.work-slider {
						.owl-stage {
							padding: 20px 0;
						}

						.gallery-link {
							display: block;
							box-shadow: 0 0 15px rgba($black, 0.15);
						}
					}
				}
			}
		}
	}
}

// other-services-section
// =========================================================================
.other-services-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		.card {
			@extend .default-card;

			.card-subtitle {
				display: none;
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}
