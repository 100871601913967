// delay
@keyframes delay {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// flip
.flip {
	backface-visibility: hidden;
	transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
	transition-property: transform;
	transition-duration: 1s;
	animation-name: flip;
}

@keyframes flip {
	0% {
		transform: perspective(2500px) rotateX(-100deg);
	}

	100% {
		transform: perspective(2500px) rotateX(0);
	}
}

// https://animista.net/play/text/focus-in/text-focus-in
@keyframes text-focus-in {
	0% {
		filter: blur(12px);
		opacity: 0;
	}

	100% {
		filter: blur(0px);
		opacity: 1;
	}
}

// https://animista.net/play/background/ken-burns/kenburns-top-left
@keyframes kenburns-top-left {
	0% {
		transform: scale(1) translate(0, 0);
		transform-origin: 16% 16%;
	}

	100% {
		transform: scale(1.25) translate(-20px, -15px);
		transform-origin: top left;
	}
}


// https://animista.net/play/background/ken-burns/kenburns-bottom-right
@keyframes kenburns-bottom-right {
	0% {
		transform: scale(1) translate(0, 0);
		transform-origin: 84% 84%;
	}

	100% {
		transform: scale(1.25) translate(20px, 15px);
		transform-origin: right bottom;
	}
}

// ====================================================================
// ====================================================================
// ====================================================================
// ====================================================================
@-webkit-keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@-webkit-keyframes kenburns-top-right {
	0% {
		-webkit-transform: scale(1) translate(0, 0);
		transform: scale(1) translate(0, 0);
		-webkit-transform-origin: 84% 16%;
		transform-origin: 84% 16%;
	}

	100% {
		-webkit-transform: scale(1.25) translate(20px, -15px);
		transform: scale(1.25) translate(20px, -15px);
		-webkit-transform-origin: right top;
		transform-origin: right top;
	}
}

@keyframes kenburns-top-right {
	0% {
		-webkit-transform: scale(1) translate(0, 0);
		transform: scale(1) translate(0, 0);
		-webkit-transform-origin: 84% 16%;
		transform-origin: 84% 16%;
	}

	100% {
		-webkit-transform: scale(1.25) translate(20px, -15px);
		transform: scale(1.25) translate(20px, -15px);
		-webkit-transform-origin: right top;
		transform-origin: right top;
	}
}

@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 3s ease-out forwards;
	animation: slide-in-bottom 3s ease-out forwards;
	animation-play-state: running !important;
}

.slide-in-right {
	-webkit-animation: slide-in-right 3s ease-out forwards;
	animation: slide-in-right 3s ease-out forwards;
	animation-play-state: running !important;
}

.kenburns-top-right {
	-webkit-animation: kenburns-top-right 3s ease-out both;
	animation: kenburns-top-right 3s ease-out both;
}
